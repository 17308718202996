import Resizer from "react-image-file-resizer";
import React, { useEffect, useRef } from "react";
import { Input } from "antd";
import moment from "moment";
import "moment/locale/ja";

export const RESIZE_FILE = (file, type) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1024,
      1024,
      "JPEG",
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      type
    );
  });

/* ROUTES */
export const ADMIN_LOGIN_ROUTE = "/login";
export const ADMIN_PANEL_ROUTE = "/panel";
export const FORM_ROUTE = "/form";

/* CHECK MOUNTED REACT */
export function USE_IS_MOUNTED_REF() {
  const isMountedRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;
    return () => (isMountedRef.current = false);
  });

  return isMountedRef;
}
/* RESPONSE TYPE */
export const RESPONSE_PERMISSION_ERROR = 401;
export const RESPONSE_SESSION_ERROR = 403;
export const RESPONSE_NOT_ACCEPTABLE_ERROR = 406;
export const RESPONSE_CONFLICT_ERROR = 409;
export const RESPONSE_SYSTEM_ERROR = 500;

/* ALERT MESSAGES */
export const ERROR_SYSTEM_MSG = "システムエラー";
export const ERROR_SESSION_MSG = "もう一度ログインしてください";
export const ERROR_401_MSG = "許可が足りないです。";
export const ERROR_404_MSG = "アクセスしたページは存在しません";
export const ERROR_LINE_403_MSG = "LINEアプリからアクセスしてください";
export const ERROR_LINE_FRIEND_MSG = "LINEで友だち追加してアクセスしてください";
export const ERROR_ADMIN_LOGIN_MISMATCH_MSG =
  "ユーザー名またはパスワードが間違っています";
export const ERROR_TELEPHONE_UNIQUE_MSG =
  "この電話番号はすでに登録されています";
export const ERROR_TELEPHONE_EMAIL_UNIQUE_MSG =
  "電話番号またはメールアドレスがすでに登録されています";
export const WARN_POSTAL_CODE_WRONG_MSG = "郵便番号を確認してください";
export const WARN_AUDIENCE_NAME_EXIST_MSG =
  "このオーディエンス名はすでに存在しています";
export const WARN_IMAGE_TOO_BIG = "画像サイズが大きすぎます";
export const WARN_IMAGE_SIZE_DIFFERENT = "画像サイズが違います";
export const INFO_CAMERA_PERMISSION_MSG = "カメラの許可をお願いします";
export const SUCCESS_LOGIN_MSG = "ログインしました。";
export const SUCCESS_LOGOUT_MSG = "ログアウトしました。";
export const SUCCESS_CREATE_MSG = "作成しました。";
export const SUCCESS_REGISTER_MSG = "登録しました。";
export const SUCCESS_UPDATE_MSG = "編集しました。";
export const SUCCESS_DELETE_MSG = "削除しました。";
export const SUCCESS_AUDIENCE_SYNC_MSG = "オーディエンス同期しました。";
export const SUCCESS_APPROVED_MSG = "リクエストを受け取りました。";
export const SUCCESS_REJECTED_MSG = "リクエストを断りました。";
//Gender selection
export const GENDER = [
  { label: "男性", value: "男性" },
  { label: "女性", value: "女性" },
];

//Prefecture API call

export function POSTAL_CODE_INSERT_CHARACTER(str, index, value) {
  return str.substr(0, index) + value + str.substr(index);
}

export const END_DATE = moment("2022-12-31", "YYYY-MM-DD");
export const NOT_AVAILABLE_TEXT = `昆布つゆーたの毎日キャンペーンは終了いたしました。\nたくさんのご応募をいただきまして、誠にありがとうございました。`;

export class NUMERIC_INPUT extends React.Component {
  onChange = (e) => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;

    if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
      this.props.onChange(value);
    }
  };

  render() {
    return (
      <Input
        {...this.props}
        onChange={this.onChange}
        onPressEnter={(e) => e.preventDefault()}
      />
    );
  }
}

export const PREFECTURES = [
  { value: 1, label: "北海道" },
  { value: 2, label: "青森県" },
  { value: 3, label: "岩手県" },
  { value: 4, label: "宮城県" },
  { value: 5, label: "秋田県" },
  { value: 6, label: "山形県" },
  { value: 7, label: "福島県" },
  { value: 8, label: "茨城県" },
  { value: 9, label: "栃木県" },
  { value: 10, label: "群馬県" },
  { value: 11, label: "埼玉県" },
  { value: 12, label: "千葉県" },
  { value: 13, label: "東京都" },
  { value: 14, label: "神奈川県" },
  { value: 15, label: "新潟県" },
  { value: 16, label: "富山県" },
  { value: 17, label: "石川県" },
  { value: 18, label: "福井県" },
  { value: 19, label: "山梨県" },
  { value: 20, label: "長野県" },
  { value: 21, label: "岐阜県" },
  { value: 22, label: "静岡県" },
  { value: 23, label: "愛知県" },
  { value: 24, label: "三重県" },
  { value: 25, label: "滋賀県" },
  { value: 26, label: "京都府" },
  { value: 27, label: "大阪府" },
  { value: 28, label: "兵庫県" },
  { value: 29, label: "奈良県" },
  { value: 30, label: "和歌山県" },
  { value: 31, label: "鳥取県" },
  { value: 32, label: "島根県" },
  { value: 33, label: "岡山県" },
  { value: 34, label: "広島県" },
  { value: 35, label: "山口県" },
  { value: 36, label: "徳島県" },
  { value: 37, label: "香川県" },
  { value: 38, label: "愛媛県" },
  { value: 39, label: "高知県" },
  { value: 40, label: "福岡県" },
  { value: 41, label: "佐賀県" },
  { value: 42, label: "長崎県" },
  { value: 43, label: "熊本県" },
  { value: 44, label: "大分県" },
  { value: 45, label: "宮崎県" },
  { value: 46, label: "鹿児島県" },
  { value: 47, label: "沖縄県" },
];
