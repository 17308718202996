import React, { lazy, Suspense } from "react";
import { Spin } from "antd";
import { Routes, Route, Navigate } from "react-router-dom";
import * as COMMONS from "common/common";

const LoginView = lazy(() => import("./components/Login"));
const PanelView = lazy(() => import("./components/Panel"));
const RegisterForm = lazy(() => import("./components/FormRegistry"));
const Layout = lazy(() => import("./components/Layout"));
const LayoutMini = lazy(() => import("./components/LayoutMini"));

const Router = () => {
  return (
    <>
      <Suspense fallback={<Spin />}>
        <Routes>
          <Route
            path="/"
            element={<Navigate replace to={COMMONS.FORM_ROUTE} />}
          />
          <Route
            path={COMMONS.FORM_ROUTE}
            element={
              <Layout>
                <RegisterForm />
              </Layout>
            }
          />
          <Route
            path={COMMONS.ADMIN_LOGIN_ROUTE}
            element={
              <LayoutMini>
                <LoginView />
              </LayoutMini>
            }
          />
          <Route
            path={COMMONS.ADMIN_PANEL_ROUTE}
            element={
              <LayoutMini>
                <PanelView />
              </LayoutMini>
            }
          />
        </Routes>
      </Suspense>
    </>
  );
};

export default Router;
